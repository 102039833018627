import React from 'react'
function Header() {
    const style = {};
  
    return (
      <header className="header">
        <h1 style={style}>schnell Pizza Co.</h1>
      </header>
    );
  }
  export default Header